import { useMutation } from '@tanstack/react-query'
import useAgentInfoStore from '../store/agentInfo'
import useAuthStore from '../store/auth'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'

interface GenerateFullDisclosureDownloadURLRequest {
    propertyId: string
}

export const useAdminGenerateFullDisclosureDownloadURL = () => {
    const axios = axiosAdminWithoutAuth()
    const generateFullDisclosureDownloadURL = async ({ propertyId }: GenerateFullDisclosureDownloadURLRequest) => {
        try {
            const data = {
                'property_id': propertyId,
            }
            const res = await axios.post('generate_full_disclosure_download_gcs', data)
            return res
        } catch (e) {
            return null
        } finally {
        }
    }

    const { mutateAsync } = useMutation(generateFullDisclosureDownloadURL)
    return { mutateAsync }
}