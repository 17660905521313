import { useMutation } from '@tanstack/react-query'
import useAgentInfoStore from '../store/agentInfo'
import useAuthStore from '../store/auth'
import {axiosAdminWithoutAuth, axiosWithAuth} from '../utils/axios'


export const useAdminCreatePropertyRedfin = () => {
    const axios = axiosAdminWithoutAuth()
    const { address, setSuccessModalOpen } =
        useAgentInfoStore()
    const createAgent = async () => {
        try {
            const data = {
                'address': address,
            }
            const res = await axios.post('create_property_redfin', data)
            setSuccessModalOpen(true)
            return res
        } catch (e) {
            return null
        } finally {
        }
    }

    const { mutate } = useMutation(createAgent)
    return { mutate }
}
