import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    //.use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,
        resources: {
            en: {
                translation: {
                    menuAllProperties: "All properties",
                    menuMyOffer: "My offer",
                    menuHouseTour: "Property tour",
                    menuRenovationConsultation: "Renovation consultation",
                    menuMortgage: "Mortgage",
                    menuTransaction: "Transaction",
                    menuTransactionManagement: "Transaction management",
                    requestPropertyReport: "Request property report",
                    requestPropertyReportNote: "Note: we can only obtain data for properties that are currently on the market",
                    requestPropertyReportAddressLabel: "Property address:",
                    requestPropertyReportNotifNote: "When property report is ready, notify me with:",
                    requestPropertyReportSendNote: "Send request",
                    searchPropertyHint: "Search property by address, zip code, city",
                    draftNewOfferLabel: "Buyer self-written offer + expert review",
                    draftNewOfferNote: "0 commission fee",
                    expertOfferLabel: "Video chat with expert",
                    expertOfferNote: "Mon-Fri 9am-5pm",
                    onMarketLabel: "On market",
                    lastUpdateLabel: "Last update",
                    offerSearchPropertyTitle: "Find the property you'd like to place an offer on",
                    offerSearchPropertyNoteLabel: "Note: Currently, Home8 only assist with property purchases in California. We plan to expand our services across the entire U.S. in 2025.",
                    offerCheckAvailabilityLabel: "Check availability",
                    offerSearchAnotherPropertyLabel: "Search another property",
                    offerSearchPropertyContinueLabel: "Continue",
                    offerSearchNoResultLabel: "No result found",
                    offerSearchNoResultHintLabel: "We apologize, but Home8 currently does not support self-service offers for this property. If you still wish to send an offer, please contact our expert to draft offer for this property.",
                    offerDueDateLabel: "Due date",
                    offerExpirationLabel: "Offer expire",
                    offerStatusDraftLabel: "Buyer draft offer",
                    offerStatusDraftReviewLabel: "Offer draft review by expert",
                    offerStatusDraftReviewDescriptionLabel: "Usually 3 business hours. Or ask expert if you need an expedited process outside of business hours.",
                    offerStatusDraftReviewFailDescriptionLabel: "Please contact expert for details",
                    offerStatusSignLabel: "Buyer sign offer in Docusign",
                    offerStatusSignCTALabel: "Sign offer",
                    offerStatusSendLabel: "Buyer send offer to listing agent",
                    offerStatusSendCTALabel: "Send offer to listing agent",
                    offerStatusSendHintLabel: "Usually get response in 48 hrs",
                    offerStatusNegotiationLabel: "Offer under negotiation",
                    offerStatusNegotiationHintLabel: "Supported by expert",
                    offerStatusOfferAcceptedLabel: "Accepted",
                    offerStatusOfferAcceptedSubLabel: "by seller",
                    offerStatusOfferDeclinedLabel: "Rejected",
                    offerStatusOfferDeclinedSubLabel: "by seller",
                    offerStatusOfferExpiredLabel: "Never heard back",
                    offerStatusOfferExpiredSubLabel: "from listing agent",
                    offerTalkToExpertLabel: "Chat with expert",
                    offerLastSubmittedTimeLabel: "Last update",
                    offerYourPriceLabel: "Your price",
                    offerLabel: "Offer",
                    offerEmailTrackerLabel: "Email tracker",
                    offerEmailTrackerNotOpenLabel: "not read yet",
                    offerEmailTrackerOpenLabel: "read",
                    offerEditSubLabel: "Any changes need expert review",
                    offerSendOfferRequireActionLabel: "[Action required] Send offer",
                    offerSendOfferRequireActionSubLabel: "To listing agent",
                    offerSignOfferRequireActionLabel: "[Action required] Sign offer",
                    offerDraftOfferContinueLabel: "Continue editing",
                    offerContinueLabel: "Continue",
                    offerUpdateStatusLabel: "Update your offer status",
                    offerExpertNegotiationSupportLabel: "Book video chat with expert for negotiation support",
                    offerEditLabel: "How would you like to edit your offer?",
                    offerContinueEditLabel: "Continue editing",
                    offerEditMinimumChangeLabel: "Minimum changes",
                    offerEditMinimumChangeScopeLabel: "You can change:",
                    offerEditMinimumChangeOfferPriceLabel: "Offer price",
                    offerEditMinimumChangeOfferExpirationLabel: "Offer expiration time",
                    offerEditMinimumChangeExpertReviewNoteLabel: "No expert review required; you will receive the updated offer document immediately.",
                    offerEditSignificantChangeLabel: "Significant changes",
                    offerEditSignificantChangeReviewNoteLabel: "You can modify everything from your previous offer version. This update will require another round of expert review, which typically takes 3 business hours.",
                    expertAvailableLabel: "Free consultation | Mon-Fri 9am-5pm",
                    propertyReportTitle: "Property report - Unbiased data and in-depth insights",
                    propertyDataSourceLabel: "Data source",
                    propertyPublicDataLabel: "Public record",
                    propertyHighlightLabel: "Property Highlight",
                    propertyHighlightLivingAreaLabel: "living",
                    propertyHighlightLotLabel: "lot",
                    propertyHighlightBuiltInLabel: "Built in",
                    propertyHighlightDaysOnMarketLabel: "days on market",
                    propertyHighlightWinBiddingWarLabel: "Win the bidding war",
                    propertyHighlightSellingPointLabel: "Selling point",
                    propertyHighlightConcernLabel: "Concerns",
                    propertyDeepdiveLabel: "Property deep dive",
                    propertyInspectionReportLabel: "Inspection report",
                    propertyTermiteReportLabel: "Termite report",
                    propertyBackgroundStoriesLabel: "Background stories",
                    propertySellerPropertyQuestionaireLabel: "Seller property questionaire",
                    propertyTransactionDisclosureStatementLabel: "Transaction disclosure statement",
                    propertyReasonForSellingLabel: "Reason for selling",
                    propertyUpgradesAndRenovationsLabel: "Recent upgrades and renovations",
                    propertyPermitHistoryLabel: "Permit history",
                    propertyDiscrepanciesLabel: "Discrepancies",
                    propertyLegalAndTileLabel: "Legal and title",
                    propertyPlotMapIntroduction: "Below is the plot map",
                    propertyPrelimReportLabel: "Prelim report",
                    propertyNatureHazardDisclosureLabel: "Nature hazard disclosure",
                    propertyNatureHazardRisksLabel: "Nature hazard risks",
                    propertyEnvironmentalPollutionLabel: "Environmental pollution",
                    propertyPublicSchoolLabel: "Public schools",
                    communityDeepdiveLabel: "Community deep dive",
                    communityNeighborhoodLabel: "Neighborhood",
                    communitySchoolLabel: "School information",
                    communityCommuteTimeLabel: "Commute time",
                    communityAmenitiesLabel: "Amenities",
                    communityUnityProvidersLabel: "Utility providers",
                    financialAndMarketAnalysisLabel: "Financial and market analysis",
                    propertyEstimateValueLabel: "Estimate appraisal value",
                    propertyEstimateValueBaseEstimationLabel: "Base estimation",
                    propertyEstimateValueRepairCostLabel: "Repair cost",
                    propertyEstimateValueDepreciationFactorsLabel: "Depreciation factors",
                    propertyEstimateValueEstimatePriceLabel: "Total",
                    propertyListingPriceLabel: "Listing price",
                    propertyComparableSalesLabel: "Comparable sales",
                    propertyComparableSalesHistoricalHighestLabel: "Historical highest",
                    propertyComparableSalesCurrentComparableSalesLabel: "Current comparable sales",
                    propertyComparableSalesListingLabel: "Listing",
                    propertyComparableSalesSoldLabel: "Sold",
                    propertyMarketTrendLabel: "Market trend",
                    propertyCostOfOwnershipLabel: "Cost of ownership",
                    propertyCostOfOwnershipTotalMonthlyCostLabel: "Total Monthly Cost of Ownership",
                    propertyCostOfOwnershipDownPaymentLabel: "Down Payment",
                    propertyCostOfOwnershipLoanAmountLabel: "Loan Amount",
                    propertyCostOfOwnershipMonthlyMortgagePaymentLabel: "Monthly Mortgage Payment",
                    propertyCostOfOwnershipMonthlyPropertyTaxLabel: "Monthly Property Tax",
                    propertyCostOfOwnershipMonthlyInsuranceLabel: "Monthly Insurance",
                    propertyRentalEstimateLabel: "Rental estimate",
                    propertyRentalEstimateLongTermRentLabel: "Long term rent",
                    propertyRentalEstimateShortTermRentLabel: "Short term rent",
                    propertyListingAndTransactionHistoryLabel: "Listing and transaction history",
                    propertyListingAgentContactLabel: "Listing agent contact",
                    propertyOfferInsightsLabel: "Offer insights",
                    propertyViewOfferLabel: "View my current offer status",
                    propertyViewOfferHintLabel: "Continue where you left off",
                    propertyStatusOnSaleLabel: "For sale",
                    propertyStatusPendingLabel: "Pending",
                    propertyStatusContingentLabel: "Contingent",
                    propertyStatusSoldLabel: "Sold",
                    chatbotOpenQuestion: "How much does it cost to fix the problems?",
                    chatbotQuestionStarter: "Type your question",
                    chatbotAgentDescriptionLabel: "Your home-buying advisor",
                    editLabel: "Edit",
                    viewLabel: "View",
                    createNewLabel: "Create new",
                    comingSoonLabel: "Coming soon",
                    monthUnitLabel: "mo",
                    sqftLabel: "sqft",
                    bedsLabel: "beds",
                    bathsLabel: "baths",
                    bedsShortLabel: "B",
                    bathsShortLabel: "B",
                    askLabel: "Ask",
                    listingAgentLabel: "Listing agent",
                    permitDescription: "Permit description",
                    permitClassifications: "Permit classifications",
                    permitNumber: "Permit number",
                    permitStatus: "Status",
                    permitStatusDate: "Status date",
                    permitJobValue: "Job value",
                    permitContractors: "Contractors",
                    listingAgentPhoneLabel: "Personal",
                    listingOffMarketDate: "Off market date",
                    listingOriginalListingPriceLabel: "Original listing price",
                    listingFinalListingPriceLabel: "Final listing price",
                    listingMLSListingIDLabel: "MLS listing ID",
                    transactionBuyerLabel: "Buyer",
                    transactionSellerLabel: "Seller",
                    transactionTitleCompany: "Title company",
                    botAskAILabel: "Ask AI",
                    botAskAINoteLabel: "Any question, any time",
                    botAskExpertLabel: "Ask expert",
                    botVideoLabel: "Video chat with expert",
                    botVideoNoteLabel: "Mon-Fri 9am-5pm",
                    sampleQuestionProblem: "Any problem that can not be fixed?",
                    sampleQuestionTSection: "Is the porperty facing T-section or xxx?", // To be fixed
                    sampleQuestionStructure: "Any foundation/structure issue?",
                    sampleQuestionHVAC: "Any issue with HVAC?",
                    sampleQuestionRoof: "What's the condition of the roof?",
                    sampleQuestionLeakage: "Any leakage?",
                    sampleQuestionElectrical: "Any electrical issue?",
                    sampleQuestionFireZone: "Is the property located on fire zone/flood zone/superfund?",
                    sampleQuestionTermite: "What's the quote for termite issues?",
                    sampleQuestionDeath: "Any death on property?",
                    sampleQuestionRenovation: "What addition/remodel/update/improvement did the seller do? With a permit?",
                    sampleQuestionModification: "Any addition or modification of floor plan without permit?",
                    sampleQuestionEasement: "Any easement?",
                    sampleQuestionNoise: "Any noise issue?",
                    sampleQuestionPollution: "Is the property located on near superfund/gas pipeline?",
                    transactionManagementBasicService: "Basic service",
                    transactionManagementAdvancedService: "Add-on service",
                    transactionManagementThirdPartyService: "3rd party service",
                    transactionManagementBasicServicePrice: "$899",
                    transactionManagementAdvancedServicePrice: "Request a quote",
                    transactionManagementThirdPartyServicePrice: "Please request quotes from vendors. Home8 has no financial ties with them.",
                    transactionManagementAgentSupport: "Assist buyers in preparing electronic signatures for transaction-related documents and address any questions regarding the documents during the transaction process.",
                    transactionManagementAgentSupportBasic: "Assist you in completing it online",
                    transactionManagementAgentSupportAdvanced: "Accompany you in completing it in person",
                    transactionManagementAgentSupportThirdParty: "Not available",
                    transactionManagementAgentInstruction: "Assist buyers to complete the transaction smoothly within the timeline required by the contract",
                    transactionManagementAgentInstructionBasic: "Remind buyers of what steps to take at each stage and connect them with relevant vendors. Vendors needed during the transaction can be introduced and connected as needed. For example, escrow companies, title services, loans, home appraisals, home inspections, home insurance, and home warranties.",
                    transactionManagementAgentInstructionAdvanced: "None",
                    transactionManagementAgentInstructionThirdParty: "Escrow companies, title services, loans, home appraisals, home inspections, home insurance, home warranties are all third-party vendors. The costs for these vendors are not included in Home8's  transaction management and buyers will need to quote by themselves.",
                    transactionManagementLenderSign: "Notarize and sign with the lender and property owner",
                    transactionManagementLenderSignBasic: "Coordinate notarized signing between buyers, lenders, and title companies within the United States",
                    transactionManagementLenderSignAdvanced: "If the buyer is not within the United States, an agent can be provided to assist",
                    transactionManagementLenderSignThirdParty: "Not available",
                    transactionManagementGrantDeed: "After the transfer is complete, obtain the latest property ownership certificate (Grant Deed) and package all documents related to the property purchase and transfer (including the final version of the purchase documents, Final Statement) for the buyer to keep",
                    transactionManagementGrantDeedBasic: "Digital copy",
                    transactionManagementGrantDeedAdvanced: "Hard copy",
                    transactionManagementGrantDeedThirdParty: "Not available",
                    transactionManagementAgentAssist: "After the transfer is complete, assist the buyer in collecting the keys, changing the locks, and guiding the setup of utilities (water, electricity, gas, trash, internet, etc",
                    transactionManagementAgentAssistBasic: "Assist you in completing it online",
                    transactionManagementAgentAssistAdvanced: "Accompany you in completing it in person",
                    transactionManagementAgentAssistThirdParty: "Lock changing is a third-party service. The costs for the vendor is not included in Home8's  transaction management and buyers will need to quote by themselves.",
                    transactionManagementAgentInspect: "Inspect the property, measure the space, and provide design and repair recommendations",
                    transactionManagementAgentInspectBasic: "Recommend vendors, coordinate schedules, and assist with communication online",
                    transactionManagementAgentInspectAdvanced: "Accompany you in completing it in person",
                    transactionManagementAgentInspectThirdParty: "Not available",
                    transactionManagementAgentPostSale: "Post-sale service",
                    transactionManagementAgentPostSaleBasic: "None",
                    transactionManagementAgentPostSaleAdvanced: "Moving, purchasing furniture, home maintenance, interior design and renovation, remodeling, tax assistance, rental property management, etc",
                    transactionManagementAgentPostSaleThirdParty: "Not available",
                    priceNegotiationTitle: "How to work with listing agent",
                    priceNegotiationOverviewTitle: "Overview",
                    priceNegotiationBeforeOfferTitle: "Before you send offer",
                    priceNegotiationBeforeOfferBulletPointOne: "Ask the listing agent about the offer due.",
                    priceNegotiationBeforeOfferBulletPointTwo: "The listing agent may adjust the offer deadline periodically. Please stay in communication with them to ensure you have the most up-to-date information on the offer due.",
                    priceNegotiationBeforeOfferBulletPointThree: "You can ask the listing agent if they are open to accepting a preemptive offer. If they are, you may consider submitting one. However, if you choose not to, the seller might accept a preemptive offer from another buyer.",
                    priceNegotiationBeforeOfferBulletPointFour: "If the listing agent states there is no set offer due and they are accepting offers as they come, it means any buyer can submit an offer at any time. The seller will accept an offer if it meets their expectations.",
                    priceNegotiationFirstOfferTitle: "Drafting your initial offer email",
                    priceNegotiationFirstOfferBulletPointOne: "Demonstrate to the listing agent that you are a serious buyer by highlighting three key points: you have sufficient funds to close the deal, you are committed to not withdrawing the offer, and you are knowledgeable enough to complete the transaction independently, with expert assistance if needed.",
                    priceNegotiationFirstOfferEmailTemplateTitle: "Email template",
                    priceNegotiationFirstOfferCommunicationTitle: "Communication via phone/text",
                    priceNegotiationAfterOfferTitle: "After you send offer",
                    priceNegotiationAfterOfferBulletPointOne: "The listing agent typically sends a confirmation email after receiving your offer.",
                    priceNegotiationAfterOfferBulletPointTwo: "After sending your offer, follow up with the listing agent every 1-2 hours. Gather additional information from these follow-ups, then adjust the price and terms according to the insights gained and your own strategy.",
                    priceNegotiationAfterOfferBulletPointThree: "Once the listing agent has gathered all the offers, they will present them to the seller.",
                    priceNegotiationOfferResultTitle: "Bidding outcomes: offer accepted, offer rejected, or no response",
                    priceNegotiationOfferResultBulletPointOne: "If the listing agent does not respond to your email or text message, or fails to answer your call, it typically indicates they are considering another offer or that your offer is significantly below the seller's expectations.",
                    priceNegotiationOfferResultBulletPointTwo: "In the SF Bay Area, the listing agent typically informs you of the bidding outcomes on the same day the offers are due. If the listing agent is unable to provide the outcomes on that day, please continue to communicate with them the following day.",
                    priceNegotiationOfferResultBulletPointThree: "If the day after the offer deadline the property's status has not changed to pending, you can ask the listing agent for clarification on what occurred (it could be that no offers were received or that the winning bidder has not yet transferred their deposit). In this case, you may still have the opportunity to submit an offer for the property, so please reach out to the listing agent for more details.",
                    priceNegotiationOfferResultDeclineTitle: "If offer rejected, communicate the following points via phone/text",
                    priceNegotiationOfferResultNoResponseTitle: "If no reponse, communicate the following points via phone/text",
                    priceNegotiationOfferResultInclineAcceptTitle: "If the seller is inclined to accept your offer, the listing agent may provide some information in advance, such as:",
                    priceNegotiationOfferResultInclineAcceptBulletPointOne: "Your offer has the highest price among all the offers they have received.",
                    priceNegotiationOfferResultInclineAcceptBulletPointTwo: "The listing agent will suggest that the seller accept your offer.",
                    priceNegotiationOfferResultNoResultWithinOneDayTitle: "If the seller is unwilling to provide a bidding outcome within a day, you can employ some strategies to encourage them to give you the result as soon as possible, such as:",
                    priceNegotiationOfferResultNoResultWithinOneDayBulletPointOne: "Express to the seller how much you appreciate their property. Share your plans for the property if your offer is accepted.",
                    priceNegotiationOfferResultNoResultWithinOneDayBulletPointTwo: "You intend to submit an offer for another property with an offer due tomorrow. If you don't receive the bidding outcome today, you plan to send offer to the other property tomorrow.",
                    priceNegotiationOfferButtonContent: "How to work with listing agent",
                    priceNegotiationOfferButtonSubContent: "Best practice",
                    priceNegotiationEmailTemplateValue: "I am a very strong and qualified buyer of this property. Financials are very ready at this point. You can surely expect the deposits will be in on the coming business day. I also understand that I am representing myself to do this transaction. My transaction coordinator will help me complete all the delivery of documents. I understand how to work with the lender and escrow to complete the transaction. If you have any concerns or questions, please feel free to call me. I could be reached by the phone number at xxx-xxx-xxxx."
                }
            },
            zh: {
                translation: {
                    menuAllProperties: "房产报告",
                    menuMyOffer: "我的报价",
                    menuHouseTour: "看房",
                    menuRenovationConsultation: "装修咨询",
                    menuMortgage: "贷款",
                    menuTransaction: "交易",
                    menuTransactionManagement: "交易管理",
                    requestPropertyReport: "获取房产报告",
                    requestPropertyReportNote: "注意: 我们只能获取正在上市出售的房产的数据",
                    requestPropertyReportAddressLabel: "房产地址：",
                    requestPropertyReportNotifNote: "当报告准备好时，用下面的方式通知我:",
                    requestPropertyReportSendNote: "发送请求",
                    searchPropertyHint: "通过地址，邮编，城市搜索房产",
                    draftNewOfferLabel: "买家自助报价+专家审核",
                    draftNewOfferNote: "免佣金",
                    expertOfferLabel: "和专家视频通话",
                    expertOfferNote: "周一到周五 9am-5pm",
                    onMarketLabel: "出售中",
                    lastUpdateLabel: "上次更新",
                    offerSearchPropertyTitle: "查询您想报价的房子",
                    offerSearchPropertyNoteLabel: "注意：现在Home8仅支持加州的房产购买。我们计划在2025年持续推向全美。",
                    offerCheckAvailabilityLabel: "查询",
                    offerSearchAnotherPropertyLabel: "查询其他房产",
                    offerSearchPropertyContinueLabel: "继续",
                    offerSearchNoResultLabel: "无结果",
                    offerSearchNoResultHintLabel: "很抱歉Home8暂时不支持为这个房子自助报价。如果仍想为这个房产报价，请联系我们的客服为您起草报价。",
                    offerDueDateLabel: "截止时间",
                    offerExpirationLabel: "报价到期时间",
                    offerStatusDraftLabel: "买家起草报价",
                    offerStatusDraftReviewLabel: "专家审核报价草稿",
                    offerStatusDraftReviewDescriptionLabel: "通常会在3小时内(工作日9am-6pm)完成审核，想要加速请联系后台",
                    offerStatusDraftReviewFailDescriptionLabel: "请联系平台给您安排的经纪人",
                    offerStatusSignLabel: "买家对报价电子签",
                    offerStatusSignCTALabel: "电子签名",
                    offerStatusSendLabel: "买家发送报价给卖家经纪人",
                    offerStatusSendCTALabel: "发送报价给卖家经纪人",
                    offerStatusSendHintLabel: "通常卖家经纪人会在48小时之内回复",
                    offerStatusNegotiationLabel: "报价协商中",
                    offerStatusNegotiationHintLabel: "由议价专家支持",
                    offerStatusOfferAcceptedLabel: "接受",
                    offerStatusOfferAcceptedSubLabel: "被卖方",
                    offerStatusOfferDeclinedLabel: "拒绝",
                    offerStatusOfferDeclinedSubLabel: "被卖方",
                    offerStatusOfferExpiredLabel: "没消息",
                    offerStatusOfferExpiredSubLabel: "从卖方经纪人",
                    offerTalkToExpertLabel: "联系专家",
                    offerYourPriceLabel: "您的出价",
                    offerLastSubmittedTimeLabel: "上次更新",
                    offerLabel: "报价",
                    offerEmailTrackerLabel: "报价邮件追踪",
                    offerEmailTrackerNotOpenLabel: "邮件未打开",
                    offerEmailTrackerOpenLabel: "邮件已打开",
                    offerEditSubLabel: "任何修改都需要专家审核",
                    offerSendOfferRequireActionLabel: "[需要行动] 发送报价",
                    offerSendOfferRequireActionSubLabel: "给卖方经纪人",
                    offerSignOfferRequireActionLabel: "[需要行动] 电子签名",
                    offerDraftOfferContinueLabel: "继续编辑",
                    offerContinueLabel: "继续",
                    offerUpdateStatusLabel: "更新您的报价状态",
                    offerExpertNegotiationSupportLabel: "和议价专家预约视频会议",
                    offerEditLabel: "您想如何修改报价？",
                    offerContinueEditLabel: "继续编辑",
                    offerEditMinimumChangeLabel: "小改动",
                    offerEditMinimumChangeScopeLabel: "小改动包括：",
                    offerEditMinimumChangeOfferPriceLabel: "价格",
                    offerEditMinimumChangeOfferExpirationLabel: "报价有效时间",
                    offerEditMinimumChangeExpertReviewNoteLabel: "无需专家审核；您即刻可以拿到修改完成的报价文件。",
                    offerEditSignificantChangeLabel: "大改动",
                    offerEditSignificantChangeReviewNoteLabel: "您可以修改上一次报价中的任何内容。此次修改须经过专家审核，通常需要3工作小时。",
                    expertAvailableLabel: "免费咨询 | 周一到周五 9am-5pm",
                    propertyReportTitle: "房产报告 - 公正数据和深度洞察",
                    propertyDataSourceLabel: "数据源",
                    propertyPublicDataLabel: "公开数据",
                    propertyHighlightLabel: "房产亮点",
                    propertyHighlightLivingAreaLabel: "居住面积",
                    propertyHighlightLotLabel: "土地面积",
                    propertyHighlightBuiltInLabel: "建于",
                    propertyHighlightDaysOnMarketLabel: "天已待售",
                    propertyHighlightWinBiddingWarLabel: "赢得议价",
                    propertyHighlightSellingPointLabel: "卖点",
                    propertyHighlightConcernLabel: "问题",
                    propertyDeepdiveLabel: "房产深度分析",
                    propertyInspectionReportLabel: "房产检查报告",
                    propertyTermiteReportLabel: "房产白蚁报告",
                    propertyBackgroundStoriesLabel: "背景信息",
                    propertySellerPropertyQuestionaireLabel: "卖家房屋问卷",
                    propertyTransactionDisclosureStatementLabel: "交易披露信息表",
                    propertyReasonForSellingLabel: "卖房原因",
                    propertyUpgradesAndRenovationsLabel: "近期维修/装修/加建",
                    propertyPermitHistoryLabel: "建筑许可证",
                    propertyDiscrepanciesLabel: "与公开数据不一致的信息",
                    propertyLegalAndTileLabel: "产权相关信息",
                    propertyPlotMapIntroduction: "产权地图",
                    propertyPrelimReportLabel: "产权调查报告",
                    propertyNatureHazardDisclosureLabel: "自然灾害报告",
                    propertyNatureHazardRisksLabel: "自然灾害风险",
                    propertyEnvironmentalPollutionLabel: "环境污染",
                    propertyPublicSchoolLabel: "公立学校",
                    propertyViewOfferLabel: "查看报价",
                    propertyViewOfferHintLabel: "继续您之前的进度",
                    communityDeepdiveLabel: "社区深度分析",
                    communityNeighborhoodLabel: "社区概述",
                    communitySchoolLabel: "学区信息",
                    communityCommuteTimeLabel: "交通",
                    communityAmenitiesLabel: "周边配套",
                    communityUnityProvidersLabel: "水电煤气和垃圾",
                    financialAndMarketAnalysisLabel: "财务和市场分析",
                    propertyEstimateValueLabel: "预评估价格",
                    propertyEstimateValueBaseEstimationLabel: "基础估价",
                    propertyEstimateValueRepairCostLabel: "维修价格",
                    propertyEstimateValueDepreciationFactorsLabel: "折价",
                    propertyEstimateValueEstimatePriceLabel: "总共",
                    propertyListingPriceLabel: "挂牌价",
                    propertyComparableSalesLabel: "近期可比交易记录",
                    propertyComparableSalesHistoricalHighestLabel: "历史最高价",
                    propertyComparableSalesCurrentComparableSalesLabel: "近期可比交易",
                    propertyComparableSalesListingLabel: "挂牌价",
                    propertyComparableSalesSoldLabel: "售价",
                    propertyMarketTrendLabel: "市场走势",
                    propertyCostOfOwnershipLabel: "持有成本",
                    propertyCostOfOwnershipTotalMonthlyCostLabel: "平均月支出",
                    propertyCostOfOwnershipDownPaymentLabel: "首付",
                    propertyCostOfOwnershipLoanAmountLabel: "贷款额",
                    propertyCostOfOwnershipMonthlyMortgagePaymentLabel: "月供",
                    propertyCostOfOwnershipMonthlyPropertyTaxLabel: "月地产税",
                    propertyCostOfOwnershipMonthlyInsuranceLabel: "月保险金",
                    propertyRentalEstimateLabel: "租金评估",
                    propertyRentalEstimateLongTermRentLabel: "长租",
                    propertyRentalEstimateShortTermRentLabel: "短租",
                    propertyListingAndTransactionHistoryLabel: "上市和交易历史",
                    propertyListingAgentContactLabel: "卖房经纪联系方式",
                    propertyOfferInsightsLabel: "出价指导信息",
                    propertyStatusOnSaleLabel: "出售中",
                    propertyStatusPendingLabel: "Pending",
                    propertyStatusContingentLabel: "Contingent",
                    propertyStatusSoldLabel: "已售出",
                    chatbotOpenQuestion: "需要花多少钱来修复房屋问题？",
                    chatbotQuestionStarter: "输入你的问题",
                    chatbotAgentDescriptionLabel: "你的买房顾问",
                    editLabel: "修改",
                    viewLabel: "查看",
                    createNewLabel: "新建",
                    comingSoonLabel: "开发中",
                    monthUnitLabel: "月",
                    sqftLabel: "平方英尺",
                    bedsLabel: "卧室",
                    bathsLabel: "浴室",
                    bedsShortLabel: "卧",
                    bathsShortLabel: "浴",
                    askLabel: "询问",
                    listingAgentLabel: "卖方经纪人",
                    permitDescription: "许可证描述",
                    permitClassifications: "许可证分类",
                    permitNumber: "许可证编号",
                    permitStatus: "状态",
                    permitStatusDate: "状态生效日期",
                    permitJobValue: "项目费用",
                    permitContractors: "承包商",
                    listingAgentPhoneLabel: "电话",
                    listingOffMarketDate: "下市日期",
                    listingOriginalListingPriceLabel: "初始挂牌价格",
                    listingFinalListingPriceLabel: "最终挂牌价格",
                    listingMLSListingIDLabel: "挂牌编号(MLS listing ID)",
                    transactionBuyerLabel: "买家",
                    transactionSellerLabel: "卖家",
                    transactionTitleCompany: "产权公司",
                    botAskAILabel: "问AI",
                    botAskAINoteLabel: "任何问题，任何时间",
                    botAskExpertLabel: "问专家",
                    botVideoLabel: "和专家视频通话",
                    botVideoNoteLabel: "周一到周五 9am-5pm",
                    sampleQuestionProblem: "有什么硬伤吗？",
                    sampleQuestionTSection: "有路冲/反弓煞吗？",
                    sampleQuestionStructure: "有地基/结构问题吗？",
                    sampleQuestionHVAC: "暖气/空调/热水器需要维修吗？",
                    sampleQuestionRoof: "屋顶需要维修吗？",
                    sampleQuestionLeakage: "有漏水吗？",
                    sampleQuestionElectrical: "有电路问题吗？",
                    sampleQuestionFireZone: "在火险区/水淹区/Superfund上吗？",
                    sampleQuestionTermite: "白蚁问题需要多少钱维修？",
                    sampleQuestionDeath: "有人在房屋中死亡吗？",
                    sampleQuestionRenovation: "做过哪些装修加建？有permit吗？",
                    sampleQuestionModification: "有违法加建改建吗？",
                    sampleQuestionEasement: "有地役权吗？",
                    sampleQuestionNoise: "有噪音问题吗？",
                    sampleQuestionPollution: "在Superfund/Gas pipeline 附近吗？",
                    transactionManagementBasicService: "基本服务",
                    transactionManagementAdvancedService: "增值服务",
                    transactionManagementThirdPartyService: "第三方服务",
                    transactionManagementBasicServicePrice: "$899",
                    transactionManagementAdvancedServicePrice: "请联系后台获取价格",
                    transactionManagementThirdPartyServicePrice: "请联系第三方服务公司获取价格，与Home8.ai无关",
                    transactionManagementAgentSupport: "帮助客户准备交易相关文件的电子签名，并回答交易过程中关于文件的问题",
                    transactionManagementAgentSupportBasic: "线上协助您完成",
                    transactionManagementAgentSupportAdvanced: "线下陪同您完成",
                    transactionManagementAgentSupportThirdParty: "不适用",
                    transactionManagementAgentInstruction: "帮助指导买家按照合同要求的时间帮助顺利完成交易",
                    transactionManagementAgentInstructionBasic: "提醒买家每一步该做什么， 对接相关第三方服务。交易中需要用到的第三方服务，可以帮助介绍和对接资源。比如托管公司（escrow）、产权（title）、贷款、房屋估价（appraisal）、房屋检查（inspection）、房屋保险、房屋保修（home warranty）等",
                    transactionManagementAgentInstructionAdvanced: "无",
                    transactionManagementAgentInstructionThirdParty: "托管公司（escrow）、产权（title）、贷款、房屋估价（appraisal）、房屋检查（inspection）、房屋保险、房屋保修（home warranty）等都是第三方服务，它们所需要的服务费用是不包含在Home8的过户管理之内，需要买家自己和这些第三方服务公司联系获取价格",
                    transactionManagementLenderSign: "贷款和产权方做公证签字",
                    transactionManagementLenderSignBasic: "协调美国境内的的买家和贷款及产权方做公证签字",
                    transactionManagementLenderSignAdvanced: "如果买家不在美国境内，可提供代理协助",
                    transactionManagementLenderSignThirdParty: "不适用",
                    transactionManagementGrantDeed: "过户完成后获取最新房屋产权证明（Grant Deed），并打包所有房屋购买至过户的相关文件（包含最终版购房文件Final Statement）发送给买家留存",
                    transactionManagementGrantDeedBasic: "电子版",
                    transactionManagementGrantDeedAdvanced: "纸质版",
                    transactionManagementGrantDeedThirdParty: "不适用",
                    transactionManagementAgentAssist: "过户完成后协助买家取钥匙、换锁、指导 utility 的开通（水电气垃圾网络等等）",
                    transactionManagementAgentAssistBasic: "线上协助您完成",
                    transactionManagementAgentAssistAdvanced: "线下陪同您完成",
                    transactionManagementAgentAssistThirdParty: "换锁是第三方服务，它所需要的服务费用是不包含在Home8的过户管理之内，需要买家自己和换锁公司联系获取价格",
                    transactionManagementAgentInspect: "验房、量房、提供设计和维修建议等",
                    transactionManagementAgentInspectBasic: "线上推荐第三方服务，协调时间，帮助沟通",
                    transactionManagementAgentInspectAdvanced: "线下陪同您完成",
                    transactionManagementAgentInspectThirdParty: "不适用",
                    transactionManagementAgentPostSale: "交易后的售后服务",
                    transactionManagementAgentPostSaleBasic: "无",
                    transactionManagementAgentPostSaleAdvanced: "搬家、买家具、房屋维护、室内设计和装修、改造、报税帮助、出租房管理等",
                    transactionManagementAgentPostSaleThirdParty: "不适用",
                    priceNegotiationTitle: "如何与卖家经纪人沟通",
                    priceNegotiationOverviewTitle: "概述",
                    priceNegotiationBeforeOfferTitle: "出价之前",
                    priceNegotiationBeforeOfferBulletPointOne: "询问卖家经纪人接收报价截止时间（offer due）。",
                    priceNegotiationBeforeOfferBulletPointTwo: "卖家经纪人可能会反复调整接收报价截止时间（offer due），请及时联系卖家经纪人获取更新。",
                    priceNegotiationBeforeOfferBulletPointThree: "可以询问卖家经纪人是否接受先发制人出价（preemptive offer），如果对方接受，您可以考虑先发制人出价。同时，如果您不采用先发制人出价，那么这个房子有可能会被其他买家以先发制人出价买走。",
                    priceNegotiationBeforeOfferBulletPointFour: "如果卖家经纪人说房子没有接收报价截止时间（offer due），而是随时接收报价，那么意味着任何买家都可以随时出报价，出价达到卖家心理预期之后就会被卖出。",
                    priceNegotiationFirstOfferTitle: "准备第一次报价",
                    priceNegotiationFirstOfferBulletPointOne: "从3个方面向卖家经纪人展现严肃认真的买家形象：有足够的资金完成交易，中途不会撤销报价，进入合同之后，您知道如何完成过户手续或者您有专业人士帮助您完成过户手续。",
                    priceNegotiationFirstOfferEmailTemplateTitle: "邮件模版",
                    priceNegotiationFirstOfferCommunicationTitle: "电话/短信沟通重点：",
                    priceNegotiationAfterOfferTitle: "发送报价之后",
                    priceNegotiationAfterOfferBulletPointOne: "通常卖家经纪人会回邮件表示确认收到了您的报价",
                    priceNegotiationAfterOfferBulletPointTwo: "发送报价之后，推荐您以打电话的形式，每1-2小时与卖家经纪人联系一次获取最新进展，以此来调整您的报价",
                    priceNegotiationAfterOfferBulletPointThree: "卖家经纪人收到所有报价和bid之后，会向卖家本人展示所有offer，通常卖家会选择净收入最大的报价",
                    priceNegotiationOfferResultTitle: "出结果 - 报价被接受/被拒绝/卖家经纪人无回信",
                    priceNegotiationOfferResultBulletPointOne: "如果经纪人无回信，通常意味着他/她正在考虑别的买家，或者您的出价与卖家心理预期相差较大。",
                    priceNegotiationOfferResultBulletPointTwo: "在北加州，通常接收报价截止日期当天会出结果。如果卖家经纪人明确表示当天不能出结果，请第二天继续与之保持联系。",
                    priceNegotiationOfferResultBulletPointThree: "接收报价截止时间（offer due）之后一天，如果房子在各个平台的状态不是pending，可以询问卖家经纪人什么情况，是不是没收到offer或者进合同的买家没交定金。如果您依然对这个房子还有兴趣，此时您可能还有机会为这个房子报价，具体情况请与卖家经纪人商议。",
                    priceNegotiationOfferResultDeclineTitle: "如果卖家经纪人拒绝了您的报价，推荐您以电话沟通以下内容：",
                    priceNegotiationOfferResultNoResponseTitle: "如果卖家经纪人不回信，推荐您以电话沟通以下内容：",
                    priceNegotiationOfferResultInclineAcceptTitle: "如果卖家有可能接受您的报价，卖家经纪人可能会提前透露一些内容，比如：",
                    priceNegotiationOfferResultInclineAcceptBulletPointOne: "您现在的出价是他们收到的最高报价",
                    priceNegotiationOfferResultInclineAcceptBulletPointTwo: "卖家经纪人会推荐卖家本人接受你的报价",
                    priceNegotiationOfferResultNoResultWithinOneDayTitle: "如果卖家在一天之内不能给出结果，您可以使用小技巧让卖家尽快公布结果，比如：",
                    priceNegotiationOfferResultNoResultWithinOneDayBulletPointOne: "表达您有多么喜爱这所房子，如果您能买到它，未来会有什么展望",
                    priceNegotiationOfferResultNoResultWithinOneDayBulletPointTwo: "您还看中了一所房子明天是收报价截止日期（offer due），如果今天得不到结果的话，明天就去给那所房子报价了",
                    priceNegotiationOfferButtonContent: "如何与卖家经纪人沟通",
                    priceNegotiationOfferButtonSubContent: "最佳实践",
                    priceNegotiationEmailTemplateValue: "I am a very strong and qualified buyer of this property. Financials are very ready at this point. You can surely expect the deposits will be in on the coming business day. I also understand that I am representing myself to do this transaction. My transaction coordinator will help me complete all the delivery of documents. I understand how to work with the lender and escrow to complete the transaction. If you have any concerns or questions, please feel free to call me. I could be reached by the phone number at xxx-xxx-xxxx."
                }
            }
        }
    });

export default i18n;