import classNames from "classnames";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Fold } from "../static/Fold.svg";
import { ReactComponent as Unfold } from "../static/Unfold.svg";

type Props = {
  isOpen: Boolean;
  title: string;
  children: ReactNode;
  onToggle: (value: boolean) => void;
};

function Collapse({ isOpen, title, onToggle, children }: Props) {
  return (
    <div className="flex flex-col w-full">
      <div className={classNames("rounded-t-[20px] bg-blue-50 p-5 text-xl font-bold flex items-center justify-between", {
        "rounded-b-[20px]": !isOpen
      })}>
        <div>{title}</div>
        {isOpen ? (
          <Fold className="cursor-pointer" onClick={() => onToggle(false)} />
        ) : (
          <Unfold className="cursor-pointer" onClick={() => onToggle(true)} />
        )}
      </div>
      {isOpen && <div className="p-5 bg-white flex justify-center rounded-b-[20px]">
        {children}
      </div>}
    </div>
  );
}

export default Collapse;
