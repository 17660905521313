import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import Button, { ButtonType } from '../components/Button'
import useAgentInfoStore from '../store/agentInfo'
import { ReactComponent as File } from '../static/File.svg'
import Input from '../components/Input'
import Modal from 'react-modal'
import { ReactComponent as Success } from '../static/Success.svg'
import { ReactComponent as Error } from '../static/Error.svg'
import Navbar from '../components/Navbar'
import useAuthStore from '../store/auth'
import Login from './Login'
import {useAdminCreatePropertyRedfin} from "../query/useAdminCreatePropertyRedfin";

function AdminCreatePropertyRedfin() {
    const {
        address,
        setAddress,
        initForRedfinCreate,
        successModalOpen,
        setSuccessModalOpen,
    } = useAgentInfoStore()
    const navigate = useNavigate()
    const { mutate: createAgent } = useAdminCreatePropertyRedfin()
    const enableCreate =
        Boolean(address)
    const [uploading, setUploading] = useState(false)

    useEffect(() => {
        initForRedfinCreate()
    }, [])

    const { isLogin } = useAuthStore()
    if (!isLogin) {
        return <Login/>
    }
    return (
        <div className="flex justify-center pt-[156px] pb-[200px]">
            <Navbar />
            <Modal
                shouldCloseOnOverlayClick={false}
                ariaHideApp={false}
                isOpen={successModalOpen}
                onAfterOpen={() => {
                    setTimeout(() => {
                        setSuccessModalOpen(false)
                        navigate('/admin/properties')
                    }, 2000)
                }}
                onRequestClose={() => {
                    setSuccessModalOpen(false)
                }}
                style={{
                    content: {
                        width: 400,
                        height: 200,
                        inset: 'unset',
                        padding: 0,
                        border: 'none',
                        borderRadius: '16px',
                        boxShadow:
                            '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
                        overflow: 'hidden',
                    },
                    overlay: {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: 'rgba(0, 0, 0, 0.55)',
                        zIndex: 100,
                    },
                }}
                htmlOpenClassName="overflow-hidden"
                shouldCloseOnEsc={true}
            >
                <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
                    <Success />
                    <div className="text-2xl font-semibold">Property created</div>
                </div>
            </Modal>
            <div className="flex flex-col items-center w-[1024px] gap-6">
                <div className="flex items-center justify-start mb-[40px] w-full px-6 py-4">
                    <div className="text-[40px] font-bold">Create property with address</div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex flex-col gap-2 px-6 w-full">
                        <div className="text-base font-bold">Address</div>
                        <div className="text-base">Required, 200 characters max (e.g. 530 Colusa Avenue, Berkeley, CA 94707)</div>
                    </div>
                    <Input
                        value={address}
                        isError={false}
                        onChange={(e) => {
                            setAddress((e.target as HTMLInputElement).value)
                        }}
                    />
                </div>

                <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
                    <Button
                        type={ButtonType.PRIMARY}
                        content="Create new property"
                        disabled={!enableCreate || uploading}
                        onClick={() => {
                            createAgent()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdminCreatePropertyRedfin
